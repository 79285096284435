var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "load-pick-detail-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": _vm.$route.query.delivery_name,
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "操作"
    },
    on: {
      "click-right": _vm.handleClickRight
    }
  }), _c('div', {
    staticClass: "filter"
  }, [_c('form', {
    attrs: {
      "action": "/"
    }
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入商品名称",
      "clearable": ""
    },
    on: {
      "search": _vm.handleSearch,
      "clear": _vm.handleClear
    },
    model: {
      value: _vm.goodsName,
      callback: function callback($$v) {
        _vm.goodsName = $$v;
      },
      expression: "goodsName"
    }
  })], 1)]), _c('div', {
    staticClass: "app-main-search-container"
  }, [_c('div', {
    staticClass: "load-pick-detail-container__content"
  }, _vm._l(_vm.filterGoodsList.length > 0 ? _vm.filterGoodsList : _vm.goodses, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "load-pick-detail-container__content__goods"
    }, [_c('load-pick-goods', {
      attrs: {
        "goods": item
      },
      on: {
        "load-pick-goods": _vm.handleLoadPickGoods,
        "image-preview": _vm.handleImagePreview
      }
    })], 1);
  }), 0)]), _vm.goodses.length > 0 ? _c('div', {
    staticClass: "fixed-bottom-btn load-pick-detail-container__footer"
  }, [_c('van-button', {
    attrs: {
      "disabled": _vm.notLoadPickCount > 0,
      "square": "",
      "block": ""
    },
    on: {
      "click": _vm.finished
    }
  }, [_vm.notLoadPickCount === 0 ? _c('span', [_vm._v("拣货完成")]) : _c('span', [_vm._v("还有" + _vm._s(_vm.notLoadPickCount) + "种商品未拣完")])])], 1) : _vm._e(), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelected
    },
    model: {
      value: _vm.showActionSheet,
      callback: function callback($$v) {
        _vm.showActionSheet = $$v;
      },
      expression: "showActionSheet"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }