<template>
  <div class="load-pick-detail-container">
    <my-nav-bar
      :title="$route.query.delivery_name"
      left-text="返回"
      left-arrow
      right-text="操作"
      @click-right="handleClickRight"
    />
    <div class="filter">
      <form action="/">
        <van-search
          v-model="goodsName"
          placeholder="请输入商品名称"
          clearable
          @search="handleSearch"
          @clear="handleClear"
        />
      </form>
    </div>
    <div class="app-main-search-container">
      <div class="load-pick-detail-container__content">
        <div v-for="(item, index) in (filterGoodsList.length > 0 ? filterGoodsList : goodses)" :key="index" class="load-pick-detail-container__content__goods">
          <load-pick-goods
            :goods="item"
            @load-pick-goods="handleLoadPickGoods"
            @image-preview="handleImagePreview"
          />
        </div>
      </div>
    </div>
    <div v-if="goodses.length > 0" class="fixed-bottom-btn load-pick-detail-container__footer">
      <van-button :disabled="notLoadPickCount > 0" square block @click="finished">
        <span v-if="notLoadPickCount === 0">拣货完成</span>
        <span v-else>还有{{ notLoadPickCount }}种商品未拣完</span>
      </van-button>
    </div>
    <van-action-sheet v-model="showActionSheet" :actions="actions" @select="handleSelected" />
  </div>
</template>

<script>
import LoadPickGoods from './components/goods'
import loadPickApi, { checkNew } from '@/api/load_pick'
import { now } from '@/utils'
import MyNavBar from '../../components/my-nav-bar'
import { ImagePreview } from 'vant'
export default {
  name: 'LoadPickDetailContainer',
  components: { LoadPickGoods, MyNavBar },
  data() {
    return {
      goodses: [],
      loadPickLargeVolume: '',
      loadPickLeastVolume: '',
      goods: {
        large_unit: '',
        least_unit: ''
      },
      goodsName: '',
      filterGoodsList: [],
      actions: [
        { name: '取消拣货', type: 1 },
        { name: '缺货商品', type: 2 },
        { name: '取消筛选', type: 3 }
      ],
      showActionSheet: false,
      orderCount: 0,
      timer: null
    }
  },
  computed: {
    notLoadPickCount() {
      let notLoadPickCount = this.goodses.length
      this.goodses.map(goods => {
        if (goods.load_pick_finished_datetime) { notLoadPickCount-- }
      })
      return notLoadPickCount
    }
  },
  created() {
    this.getDetail()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    getDetail() {
      const params = {
        delivery_id: this.$route.query.delivery_id
      }
      this.beginLoad()
      loadPickApi.detail(params).then(res => {
        this.endLoad()
        this.goodses = res.data.list
        this.orderCount = res.data.order_count
        if (!this.timer) {
          this.timer = setInterval(() => {
            const params = {
              delivery_id: this.$route.query.delivery_id
            }
            checkNew(params).then(res => {
              if (this.orderCount !== res.data.order_count) {
                this.getDetail()
              }
            })
          }, 10000)
        }
      })
    },
    handleLoadPickGoods(goods) {
      this.goods = goods
      this.loadPickLargeVolume = goods.large_volume
      this.loadPickLeastVolume = goods.least_volume
      this.loadPickGoods()
    },
    loadPickGoods() {
      const loadPickLargeVolume = Number(this.loadPickLargeVolume)
      const loadPickLeastVolume = Number(this.loadPickLeastVolume)
      const data = {
        delivery_id: this.$route.query.delivery_id,
        client_id: this.goods.client.id,
        goods_id: this.goods.goods_id,
        load_pick_large_volume: loadPickLargeVolume,
        load_pick_least_volume: loadPickLeastVolume
      }
      this.beginLoad()
      loadPickApi.goods(data).then(res => {
        this.endLoad()
        this.goods.load_pick_finished_datetime = now()
        this.goods.load_pick_large_volume = loadPickLargeVolume
        this.goods.load_pick_least_volume = loadPickLeastVolume
        this.goods.is_load_pick = 1
        if (this.goods.load_pick_finished_datetime) {
          // 已完成的放到最下面
          const index = this.goodses.findIndex(goods => goods.goods_id === this.goods.goods_id)
          if (index !== -1) {
            const mvGoods = this.goodses[index]
            this.goodses.splice(index, 1)
            this.goodses.push(mvGoods)
          }
        }
      })
    },
    cancel() {
      this.confirm('确定取消拣货吗？').then(() => {
        const data = {
          delivery_id: this.$route.query.delivery_id
          // client_id: this.$route.query.client_id
        }
        this.beginLoad()
        loadPickApi.cancel(data).then(res => {
          this.success(res.msg)
          this.$router.go(-1)
        })
      })
    },
    handleClickRight() {
      this.showActionSheet = true
    },
    handleSelected(action) {
      if (action.type === 1) {
        this.cancel()
      } else if (action.type === 2) {
        this.filterGoodsList = this.goodses.filter(goods => {
          if (Number(goods.large_volume) !== Number(goods.load_pick_large_volume) || Number(goods.least_volume) !== Number(goods.load_pick_least_volume)) {
            return goods
          }
        })
      } else {
        this.filterGoodsList = []
      }
      this.showActionSheet = false
    },
    finished() {
      const data = {
        delivery_id: this.$route.query.delivery_id
        // client_id: this.$route.query.client_id
      }
      this.beginLoad()
      loadPickApi.finish(data).then(res => {
        this.success(res.msg)
        this.$router.go(-1)
      })
    },
    handleSearch() {
      this.filterGoodsList = this.goodses.filter(goods => {
        if (goods.name.search(this.goodsName) !== -1) {
          return goods
        }
      })
    },
    handleClear() {
      this.filterGoodsList = []
    },
    handleImagePreview(image) {
      ImagePreview([image])
    }
  }
}
</script>

<style lang="scss" scoped>
  .load-pick-detail-container {
    &__content {
      padding: 15px 15px 55px 15px;
      &__goods {
        margin-bottom: 10px;
      }
    }
    &__footer {
      background-color: #fff;
      z-index: 1;
      &__input {
        padding: 10px 15px;
      }
      .van-button {
        height: 50px;
      }
    }
    .filter {
      z-index: 2;
      position: fixed;
      left: 0;
      right: 0;
      top: 46px;
    }
  }
</style>
